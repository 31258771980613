import React, { useMemo } from 'react';
import {Report, ReportStatus} from '../../../utils/graphql/generatedTS/graphql';
import {
    Alert,
    Chip,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useAuthContext } from '../../../context/AuthContext';
import { CHANNELS_LIST } from "../../../utils/constants/Channels";
import { CheckCircle, RemoveCircle } from "@mui/icons-material";
import { Broadcaster } from "../../../utils/constants/Broadcaster";
import { DeviceTypeFilter } from "../../../utils/constants/DeviceTypeFilter";
import { useQuery } from '@apollo/client';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { ReportQueries } from '../../../utils/graphql/queries/report';
import {OnlineVideoFilter} from "../../../utils/constants/OnlineVideoFilter";


/**
 *
 * @param {React.PropsWithChildren<ISingleRowReportPreview>} props
 * @return {JSX.Element}
 * @constructor
 */


export const SingleRowReportPreview: React.FC<ISingleRowReportPreview> = ({report}) => {
    const {authenticatedIdUser} = useAuthContext();
    const { authToken } = useAuthContext();

    const { data: reportWarnings, loading, error} = useQuery(ReportQueries.GET_REPORT_WARNINGS, {
        variables: {
            idReport: report.idReport
        },
        context: generateClientContextWithHeaders(authToken),
        skip: !report.hasWarnings,
    })

    // remove the current users from the shared with list
    const sharedWith = useMemo(() => {
        return report.sharedWith?.filter(s => s?.idUser !== authenticatedIdUser)
    }, [report]);


    const broadcasterList = Object.values(Broadcaster);
    const DeviceTypeList = Object.values(DeviceTypeFilter);


    const broadcasterDisplayName = {
        [Broadcaster.MTV]: 'MTV',
        [Broadcaster.SANOMA]: 'Sanoma',
    };


    const deviceTypeDisplayName = {
        [DeviceTypeFilter.TV_SCREEN]: 'TV Screen',
        [DeviceTypeFilter.SECOND_SCREEN]: 'Small Screen',
    };

    const onlineVideoDisplayName = {
        [OnlineVideoFilter.IS_PLATFORM]: 'Sanoma IS',
    };

    const isBroadcasterInFilter = useMemo(() => {
        if (!report.filter.broadcaster || report.filter.broadcaster.length === 0) {
            return broadcasterList.map(() => true);
        }


        return broadcasterList.map((broadcaster) =>
            report.filter.broadcaster?.includes(broadcaster) ?? false
        );
    }, [report.filter.broadcaster]);


    const isDeviceTypeInFilter = useMemo(() => {
        if (!report.filter.deviceType || report.filter.deviceType.length === 0) {
            // If no device types are selected, assume all are selected
            return Object.values(DeviceTypeFilter).map(() => true);
        }


        return Object.values(DeviceTypeFilter).map((deviceType) =>
            report.filter.deviceType?.includes(deviceType) ?? false
        );
    }, [report.filter.deviceType]);


    // Create an array of booleans indicating if each channel is in report.filter.channel
    const isChannelInFilter = useMemo(() => {
        if (!report.filter.channel || report.filter.channel.length === 0) {
            // If filter.channel is undefined, null, or empty, return an array of all true
            return CHANNELS_LIST.map(() => true);
        }

        const nonEmptyFilters = report.filter.channel.filter(id => id !== '0');

        // If the only filter was "No TV Channel" (id: 0), treat it as if no filter is applied
        if (nonEmptyFilters.length === 0) {
            return CHANNELS_LIST.map(() => true);
        }

        return CHANNELS_LIST.map((channel) => {
            if (channel.id === 0) {
                // "No TV Channel" is always considered as not filtered
                return true;
            }
            return nonEmptyFilters.includes(String(channel.id));
        });

    }, [report.filter.channel]);


    const filterOnBroadcaster = isBroadcasterInFilter.includes(false);
    const filterOnDevice = isDeviceTypeInFilter.includes(false);
    const filterOnChannel = isChannelInFilter.includes(false);
    const isOnlineVideoFilterApplied = useMemo(() => {
        return !report.filter.onlineVideo || report.filter.onlineVideo.length > 0;
    }, [report.filter.onlineVideo]);

    return (

        <Grid container spacing={2}>

            {((filterOnBroadcaster || filterOnDevice || filterOnChannel || !isOnlineVideoFilterApplied) && (
                <Grid item xs={12}>
                    <Paper sx={{padding: 2, borderRadius: '15px', mb: '2px'}}>
                        <Typography variant={"h5"} fontWeight={"bold"} >FILTERS</Typography>

                        <Alert severity={"info"} sx={{ borderRadius: '10px', mt:'15px' }}>
                            <strong>Filters applied on this report:</strong>

                            {filterOnBroadcaster && (
                                <Typography fontSize={13}>
                                    • BVOD Broadcaster
                                </Typography>
                            )}
                            {filterOnChannel && (
                                <Typography fontSize={13}>
                                    • Linear Channels
                                </Typography>
                            )}
                            {filterOnDevice && (
                                <Typography fontSize={13}>
                                    • Device Type
                                </Typography>
                            )}
                            {!isOnlineVideoFilterApplied && (
                                <Typography fontSize={13}>
                                    • Online Video
                                </Typography>
                            )}
                        </Alert>
                    </Paper>
                </Grid>
            ))}



            {/* Warning */}
            <Grid item xs={12}>
                {
                    report.status !== ReportStatus.Succeeded ? null : (
                        <Paper sx={{ padding: 2, borderRadius: '15px' }}>
                            <Typography variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
                                WARNING
                            </Typography>
                            { !!reportWarnings ? (
                                <Alert severity={"warning"} sx={{ borderRadius: '10px' }}>
                                    <strong>This report contains the following warnings:</strong>
                                    {
                                        !loading && !!reportWarnings ?
                                            reportWarnings.getReportWarnings?.map(r =>
                                                <><br/><span key={r?.idReportWarning}>• {r?.description}</span></>
                                            )
                                            :
                                            ""
                                    }
                                </Alert>
                            ) : (
                                <Alert severity={"success"} sx={{ borderRadius: '10px' }}>
                                    All good!
                                </Alert>
                            )}
                        </Paper>
                    )
                }

            </Grid>


            {/* Spotgate code */}
            <Grid item md={4} sm={12}>
                <Paper sx={{padding: 2, minHeight: "100%", borderRadius: '15px'}}>
                    <Typography variant={"h5"} fontWeight={"bold"} sx={{mb: 2}}>SPOT</Typography>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>SPOTGATE CODE</TableCell>
                                    <TableCell>START DATE</TableCell>
                                    <TableCell>END DATE</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {report.spotList?.map((s) => (
                                    <TableRow
                                        key={s?.id}
                                    >
                                        <TableCell> {s?.id}</TableCell>
                                        <TableCell> {s?.periodStart}</TableCell>
                                        <TableCell>{s?.periodEnd}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>


            {/* Target */}
            <Grid item md={5} sm={12}>
                <Paper sx={{padding: 2, minHeight: "100%", borderRadius: '15px'}}>
                    <Typography variant={"h5"} fontWeight={"bold"} sx={{mb: 2}}>TARGET</Typography>
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>NAME</TableCell>
                                    <TableCell>GENDER</TableCell>
                                    <TableCell>AGE BREAKS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {report.targetList?.map((t) => (
                                    <TableRow
                                        key={t?.targetName}
                                    >
                                        <TableCell>
                                            {t?.targetName}
                                        </TableCell>
                                        <TableCell>
                                            {t?.sex}
                                        </TableCell>
                                        <TableCell>
                                            {t?.ageBreak?.map((ab) => (
                                                ab ? (
                                                    <Chip key={ab} label={ab.replace("_", "-")} color={"primary"} size={"small"}
                                                          sx={{ color: "white", mr: 0.5, mt: 0.5, fontSize: 12, paddingX: 0.5 }} />
                                                ) : null
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>


            {/* Shared with */}
            <Grid item md={3} sm={12}>
                <Paper sx={{padding: 2, minHeight: "100%", borderRadius: '15px'}}>
                    <Typography variant={"h5"} fontWeight={"bold"} sx={{mb: 2}}>SHARED WITH</Typography>
                    {
                        sharedWith && sharedWith.length > 0 ?
                            <TableContainer>
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>USERNAME</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Remove the users that are disabled, then print the list of users */}
                                        {sharedWith?.filter(u => u && !u.isDisabled).map((s) => (
                                            <TableRow
                                                key={s?.idUser}
                                            >
                                                <TableCell sx={{display: "flex", alignItems: "center"}}>
                                                    <img src={"/imgs/user-icon.svg"} width={25} style={{marginRight: 4}}/>
                                                    {s?.firstName} {s?.lastName}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <Alert severity={"info"} sx={{ borderRadius: '10px' }}>Not currently shared</Alert>
                    }
                </Paper>
            </Grid>


            {/* FILTERS - by broadcaster */}
            <Grid item md={3} sm={6}>
                <Paper sx={{ padding: 2, height: '100%', borderRadius: '15px'}}>
                    <Typography variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>SELECTED BVOD BROADCASTER</Typography>
                    <TableContainer sx={{ maxHeight: 120, overflow: 'auto' }}>
                        <Table size="small">
                            <TableBody>
                                {broadcasterList.map((broadcaster, index) => (
                                    <TableRow key={broadcaster}>
                                        <TableCell>{broadcasterDisplayName[broadcaster]}</TableCell>
                                        <TableCell align="right">
                                            {isBroadcasterInFilter[index] ? (
                                                <CheckCircle color="success" />
                                            ) : (
                                                <RemoveCircle color="disabled" />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>


            {/* FILTERS - by channel */}
            <Grid item md={3} sm={6}>
                <Paper sx={{ padding: 2, height: '100%', borderRadius: '15px' }}>
                    <Typography variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>SELECTED LINEAR CHANNEL</Typography>
                    <TableContainer sx={{ maxHeight: 120, overflow: 'auto' }}>
                        <Table size="small">
                            <TableBody>
                                {CHANNELS_LIST.slice(1).map((c, index) => (
                                    <TableRow key={c?.id}>
                                        <TableCell>{c?.name}</TableCell>
                                        <TableCell>
                                            {isChannelInFilter[index + 1] ? <CheckCircle color="success" /> : <RemoveCircle color="disabled" />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>


            {/* FILTERS - by device type */}
            <Grid item md={3} sm={6}>
                <Paper sx={{ padding: 2, height: '100%', borderRadius: '15px' }}>
                    <Typography variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>SELECTED DEVICE TYPE</Typography>
                    <TableContainer sx={{ maxHeight: 120, overflow: 'auto' }}>
                        <Table size="small">
                            <TableBody>
                                {Object.values(DeviceTypeFilter).map((deviceType, index) => (
                                    <TableRow key={deviceType}>
                                        <TableCell>{deviceTypeDisplayName[deviceType]}</TableCell>
                                        <TableCell align="right">
                                            {isDeviceTypeInFilter[index] ? (
                                                <CheckCircle color="success" />
                                            ) : (
                                                <RemoveCircle color="disabled" />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>


            {/* FILTERS - online video */}
            <Grid item md={3} sm={6}>
                <Paper sx={{ padding: 2, height: '100%', borderRadius: '15px' }}>
                    <Typography variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>SELECTED ONLINE VIDEO</Typography>
                    <TableContainer sx={{ maxHeight: 120, overflow: 'auto' }}>
                        <Table size="small">
                            <TableBody>
                                {Object.values(OnlineVideoFilter).map((onlineVideo, index) => (
                                    <TableRow key={onlineVideo}>
                                        <TableCell>{onlineVideoDisplayName[onlineVideo]}</TableCell>
                                        <TableCell align="right">
                                            {isOnlineVideoFilterApplied ? (
                                                <CheckCircle color="success" />
                                            ) : (
                                                <RemoveCircle color="disabled" />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>






    );
};


export interface ISingleRowReportPreview {
    report: Report
}


export default SingleRowReportPreview;