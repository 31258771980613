import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Box, Typography} from '@mui/material';
import CommonPageBaseLayout from '../../organism/Common.PageBaseLayout/Common.PageBaseLayout';
import { ArrowBackRounded } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import { NewReportProvider } from '../../../context/NewReportContext';
import NewReportForm from '../../organism/NewReport.Form/NewReport.Form';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {subDays} from "date-fns";

/**
 *
 * @param {React.PropsWithChildren<INewReport>} props
 * @return {JSX.Element}
 * @constructor
 */
const NewReport: React.FC<INewReport> = (props) => {
  const navigate = useNavigate();
  const {idTeam} = useParams();
  const [dateAlertMessage, setDateAlertMessage] = useState<string>("");

  const goBack = useCallback(() => {
    navigate(RouteKey.Dashboard.replace(":idTeam", idTeam as string));
  }, [idTeam]);

  useEffect(() => {
    const now = new Date();
    const dayBeforeYesterday = subDays(now, 2);
    setDateAlertMessage(
        `• Please note that SpotOn normally releases daily data at 6:00 pm EET on the following day.\n` +
        `• Please note that the Standard TV Day goes from 02:00 am EET to 02:00 am EET.`
    );
  }, []);



  return (
    <CommonPageBaseLayout>
      <NewReportProvider>
        {/*Back arrow*/}
        <Box display={"flex"} alignItems={"center"} sx={{cursor: "pointer"}} onClick={goBack}>
          <ArrowBackRounded fontSize={'small'}/>
          <Typography variant={"body2"} ml={1}>BACK</Typography>
        </Box>

        {/* Title and subtitle */}
        <Typography variant={"h1"} fontWeight={600} mt={4}>
          New Report
        </Typography>
        <Typography variant={"subtitle1"} mt={1}>
          To request a new report, fill in at least one spot and one target with a valid segment.
        </Typography>

        <Box sx={{backgroundColor: "white"}} borderRadius={5} mt={2} paddingX={2} paddingY={4}>

          <NewReportForm/>

        </Box>

        <Alert severity="info" sx={{ borderRadius: '15px', mt:'25px' }}>
          <div style={{ whiteSpace: 'pre-line' }}>
            {dateAlertMessage}
          </div>
        </Alert>



      </NewReportProvider>
    </CommonPageBaseLayout>
  );
};

export interface INewReport {

}

export default NewReport;
