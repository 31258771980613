import React, { useCallback, useEffect, useState } from 'react';
import { Spot } from '../../../utils/graphql/generatedTS/graphql';
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parse, isAfter, subDays } from 'date-fns';
import { useDebounce } from 'react-use';
import { Delete } from '@mui/icons-material';
import MandatoryAsterisk from '../../atom/MandatoryAsterisk/MandatoryAsterisk';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { SwitchTextTrack } from "../../../mui-treasury/switch-lovely";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

/** IMPORTANT: THE CUTOFF DATE MUST BE CHANGED BOTH HERE AND IN NEWREPORT.FORM IF NEEDED */
// Constants and Types
const CUTOFF_HOUR = 18;

type DateTimePartType =
    | 'year' | 'month' | 'day'
    | 'hour' | 'minute' | 'second'
    | 'weekday' | 'era' | 'timeZoneName'
    | 'literal';

export interface ISpotRow {
  position: number;
  spot: Spot;
  setSpot: (spot: Spot) => void;
  setSpotWithRepeatDate: (spot: Spot) => void;
  deleteSpot: () => void;
  repeatDates: boolean;
  drilldownBySpotgate: boolean | undefined;
  setDrilldownBySpotgate: (value: boolean) => void;
  toggleRepeatDate: () => void;
  onDateAlert: (message: string) => void;
}

const SpotRow: React.FC<ISpotRow> = (props) => {
  // State Management
  const [startDateError, setStartDateError] = useState<string>("");
  const [endDateError, setEndDateError] = useState<string>("");
  const [showPeriodAlert, setShowPeriodAlert] = useState<boolean>(false);
  const [dateAlertMessage, setDateAlertMessage] = useState<string>("");
  const [idError, setIdError] = useState<string>("");
  const [lastAlertMessage, setLastAlertMessage] = useState<string>("");

  // Spotgate Code Validation
  const [] = useDebounce(
      () => {
        if (!!props.spot.id && (!/^[sS][gG]\d{1,10}$/.test(props.spot.id))) {
          setIdError("Invalid Spotgate Code");
        }
      },
      500,
      [props.spot.id]
  );

  // Helper Functions
  const calculateDaysDifference = (startDate: Date, endDate: Date): number => {
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
  };

  const checkDateAvailability = useCallback(() => {

    if (!props.spot.periodStart || !props.spot.periodEnd) {
      return null;
    }

    const now = new Date();
    const helsinkiFormatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'Europe/Helsinki',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });


    const helsinkiHour = parseInt(helsinkiFormatter.formatToParts(now)
        .find(part => part.type === 'hour')?.value || '0');

    const isPastCutoff = helsinkiHour >= CUTOFF_HOUR;

    const cutoffDate = isPastCutoff ? subDays(now, 1) : subDays(now, 2);

    const startDate = parse(props.spot.periodStart, 'yyyy-MM-dd', new Date());
    const endDate = parse(props.spot.periodEnd, 'yyyy-MM-dd', new Date());

    const startAfterCutoff = isAfter(startDate, cutoffDate);
    const endAfterCutoff = isAfter(endDate, cutoffDate);

    if (startAfterCutoff || endAfterCutoff) {
      const message = `Please note that the report will contain data up to ${format(cutoffDate, 'dd/MM/yyyy')}. Data is updated daily at ${CUTOFF_HOUR}:00 Helsinki time.`;
      return message;
    }
    return null;
  }, [props.spot.periodStart, props.spot.periodEnd]);

  useEffect(() => {
    const message = checkDateAvailability();
    props.onDateAlert(message || "");
  }, [checkDateAvailability, props.onDateAlert]);


// Update date setters to ensure they clear alerts
  const setStartDate = useCallback((value: Date | null) => {
    if (!value) return;

    // Clear any existing alerts first
    props.onDateAlert("");
    setLastAlertMessage("");

    const date = format(value, "yyyy-MM-dd");
    const newSpot = {
      ...props.spot,
      periodStart: date
    };

    if (props.repeatDates) {
      props.setSpotWithRepeatDate(newSpot);
    } else {
      props.setSpot(newSpot);
    }
  }, [props.spot, props.setSpot, props.repeatDates, props.setSpotWithRepeatDate, props.onDateAlert]);

  const setEndDate = useCallback((value: Date | null) => {
    if (!value) return;

    // Clear any existing alerts first
    props.onDateAlert("");
    setLastAlertMessage("");

    const date = format(value, "yyyy-MM-dd");
    const newSpot = {
      ...props.spot,
      periodEnd: date
    };

    if (props.repeatDates) {
      props.setSpotWithRepeatDate(newSpot);
    } else {
      props.setSpot(newSpot);
    }
  }, [props.spot, props.setSpot, props.repeatDates, props.setSpotWithRepeatDate, props.onDateAlert]);

  const setCode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    props.setSpot({
      ...props.spot,
      id: e.target.value.trim(),
    });
    setIdError("");
  }, [props.spot, props.setSpot]);

  // Effects
  useEffect(() => {
    if (!!props.spot.periodStart && !!props.spot.periodEnd) {
      const dateStart = parse(props.spot.periodStart, 'yyyy-MM-dd', new Date());
      const dateEnd = parse(props.spot.periodEnd, 'yyyy-MM-dd', new Date());

      if (dateStart > dateEnd) {
        setEndDateError("Must be after Start date");
        return;
      }

      const daysDifference = calculateDaysDifference(dateStart, dateEnd);
      if (daysDifference > 60) {
        setShowPeriodAlert(true);
        return;
      }

      setStartDateError("");
      setEndDateError("");
    }
  }, [props.spot.periodStart, props.spot.periodEnd]);

  useEffect(() => {
    checkDateAvailability();
  }, [checkDateAvailability]);

  // Render
  return (
      <Box>
        <Box display="flex" alignItems="center" paddingY={2}>
          <Avatar
              sx={{
                width: 24,
                height: 24,
                fontSize: 14,
                backgroundColor: "#212529",
                fontWeight: "bold"
              }}
          >
            {props.position + 1}
          </Avatar>

          <TextField
              label={!!props.spot.id ? <span>Spotgate Code<MandatoryAsterisk/></span> : ""}
              placeholder="Type Spotgate Code es: sg1234"
              variant="outlined"
              size="small"
              type="text"
              value={props.spot.id}
              onChange={setCode}
              sx={{
                ml: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '10px',
                  }
                }
              }}
              error={!!idError}
              helperText={idError}
          />

          <DatePicker
              format="dd/MM/yyyy"
              onChange={setStartDate}
              value={!!props.spot.periodStart ? parse(props.spot.periodStart, 'yyyy-MM-dd', new Date()) : null}
              minDate={new Date('2024-01-01')}
              maxDate={new Date('2029-01-31')}
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    width: 200,
                    ml: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '10px',
                      }
                    }
                  },
                  label: !!props.spot.periodStart ? <span>Start Date<MandatoryAsterisk/></span> : "",
                  error: !!startDateError,
                  helperText: startDateError,
                }
              }}
          />

          <DatePicker
              format="dd/MM/yyyy"
              onChange={setEndDate}
              value={!!props.spot.periodEnd ? parse(props.spot.periodEnd, 'yyyy-MM-dd', new Date()) : null}
              minDate={new Date('2024-01-01')}
              maxDate={new Date('2029-01-31')}
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    width: 200,
                    ml: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '10px',
                      }
                    }
                  },
                  label: !!props.spot.periodEnd ? <span>End Date<MandatoryAsterisk/></span> : "",
                  error: !!endDateError,
                  helperText: endDateError,
                }
              }}
          />

          {props.position > 0 ? (
              <Box ml={2}>
                <IconButton color="error" onClick={props.deleteSpot}>
                  <Delete/>
                </IconButton>
              </Box>
          ) : (
              <Box display="flex" flexDirection="column" ml={4} alignSelf="center">
                <FormGroup>
                  <FormControlLabel
                      control={
                        <SwitchTextTrack
                            size="small"
                            checked={props.drilldownBySpotgate}
                            onChange={() => props.setDrilldownBySpotgate(!props.drilldownBySpotgate)}
                        />
                      }
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>Drill down by spotgate</span>
                          <Tooltip
                              title="In case of multi spotgate selection, the excel file contains also the figures split by each spotgate code"
                              placement="top"
                          >
                            <IconButton size="small" style={{ marginLeft: '4px', padding: '2px' }}>
                              <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                  />
                  <FormControlLabel
                      control={
                        <SwitchTextTrack
                            size="small"
                            checked={props.repeatDates}
                            onChange={props.toggleRepeatDate}
                        />
                      }
                      label="Repeat dates"
                  />
                </FormGroup>
              </Box>
          )}
        </Box>

        {lastAlertMessage && (
            <Box mt={-1} mb={2}>
              <Alert severity="info" sx={{ borderRadius: '10px' }}>
                {lastAlertMessage}
              </Alert>
            </Box>
        )}

        <Dialog
            open={showPeriodAlert}
            onClose={() => setShowPeriodAlert(false)}
            aria-labelledby="period-alert-dialog-title"
            aria-describedby="period-alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="period-alert-dialog-description">
              <Alert severity="info">
                <b>Period exceeds 60 days.</b> <br/> Running can take longer than 3 minutes
              </Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginTop: -15 }}>
            <Button
                variant="outlined"
                onClick={() => setShowPeriodAlert(false)}
                style={{ color: '#455a64', borderRadius: 15, borderColor: '#455a64'}}
            >
              Ok, got it!
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
  );
};

export default SpotRow;